import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

// document.addEventListener('DOMContentLoaded', function () {
//   const currentPath = window.location.pathname;
//   document.querySelector('.c-navigation__submenu').classList.remove('active');

//   if (currentPath.includes('portfolio')) {
//     const element = document.querySelector('[data-submenu]');
//     element.querySelector('.c-navigation__submenu').classList.add('active');
//   }
// }, false);


// FIREFIES

var WIDTH;
var HEIGHT;
var canvas;
var con;
var g;
var pxs = new Array();
var rint = 50;

$(document).ready(function(){
  WIDTH = window.innerWidth;
  HEIGHT = window.innerHeight;
	$('#container').width(WIDTH).height(HEIGHT);
	canvas = document.getElementById('firefies');
	$(canvas).attr('width', WIDTH).attr('height',HEIGHT);
	con = canvas.getContext('2d');
	for(var i = 0; i < 50; i++) {
		pxs[i] = new Circle();
		pxs[i].reset();
	}
	setInterval(draw,rint);
});

function draw() {
	con.clearRect(0,0,WIDTH,HEIGHT);
	for(var i = 0; i < pxs.length; i++) {
		pxs[i].fade();
		pxs[i].move();
		pxs[i].draw();
	}
}

function Circle() {
	this.s = {ttl:8000, xmax:5, ymax:2, rmax:10, rt:1, xdef:960, ydef:540, xdrift:4, ydrift: 4, random:true, blink:true};

	this.reset = function() {
		this.x = (this.s.random ? WIDTH*Math.random() : this.s.xdef);
		this.y = (this.s.random ? HEIGHT*Math.random() : this.s.ydef);
		this.r = ((this.s.rmax-1)*Math.random()) + 1;
		this.dx = (Math.random()*this.s.xmax) * (Math.random() < .5 ? -1 : 1);
		this.dy = (Math.random()*this.s.ymax) * (Math.random() < .5 ? -1 : 1);
		this.hl = (this.s.ttl/rint)*(this.r/this.s.rmax);
		this.rt = Math.random()*this.hl;
		this.s.rt = Math.random()+1;
		this.stop = Math.random()*.2+.4;
		this.s.xdrift *= Math.random() * (Math.random() < .5 ? -1 : 1);
		this.s.ydrift *= Math.random() * (Math.random() < .5 ? -1 : 1);
	}

	this.fade = function() {
		this.rt += this.s.rt;
	}

	this.draw = function() {
		if(this.s.blink && (this.rt <= 0 || this.rt >= this.hl)) this.s.rt = this.s.rt*-1;
		else if(this.rt >= this.hl) this.reset();
		var newo = 1-(this.rt/this.hl);
		con.beginPath();
		con.arc(this.x,this.y,this.r,0,Math.PI*2,true);
		con.closePath();
		var cr = this.r*newo;
		g = con.createRadialGradient(this.x,this.y,0,this.x,this.y,(cr <= 0 ? 1 : cr));
		g.addColorStop(0.0, 'rgba(255,255,255,'+newo+')');
		g.addColorStop(this.stop, 'rgba(255,255,255,'+(newo*.9)+')');
		g.addColorStop(1.0, 'rgba(255,255,255,0)');
		con.fillStyle = g;
		con.fill();
	}

	this.move = function() {
		this.x += (this.rt/this.hl)*this.dx;
		this.y += (this.rt/this.hl)*this.dy;
		if(this.x > WIDTH || this.x < 0) this.dx *= -1;
		if(this.y > HEIGHT || this.y < 0) this.dy *= -1;
	}

	this.getX = function() { return this.x; }
	this.getY = function() { return this.y; }
}

function cookieconsent() {
	window.cookieconsent.initialise({
		"palette": {
			"popup": {
				"background": "#000"
			},
			"button": {
				"background": "#ffffff"
			}
		},
		"theme": "edgeless",
		"position": "bottom-left",
		"type": "opt-out",
		"content": {
			"message": "Hier im Rabbithole nutzen wir Cookies zur Verbesserung der dargestellten Inhalten. Weitere Informationen zu Cookies und warum wir sie nutzen findest du jederzeit auf unserer Seite",
			"dismiss": "Nein Mann",
			"allow": "Lott Jonn!",
			"deny": "Nö keine Lust",
			"link": "Mehr erfahren"
		}
	});
}

cookieconsent();

// HIGHLIGHTING OPENING DAY

function getOpeningHours() {
  var d = new Date();
  var day = new Array(7);
  day[0] = 'Sunday';
  day[1] = 'Monday';
  day[2] = 'Tuesday';
  day[3] = 'Wednesday';
  day[4] = 'Thursday';
  day[5] = 'Friday';
  day[6] = 'Saturday';

  var n = day[d.getDay()];

  $('dl').each(function() {
    var today = $(this).attr('data-day');
    if (n === today) {
      $(this).addClass('today');
    }
  });
}

getOpeningHours();